<template>
  <div class="index-box">
    <el-row class="top">
      <el-col :span="6">
        <div class="grid-content">
          <div class="content" style="margin-left: 0">
            <div class="title">{{$t('index.区块高度')}}</div>
            <div class="text">{{ blockNumber }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <div class="content">
            <div class="title">{{$t('index.交易费')}}</div>
            <div class="text">{{ gasPrice }}Gwei</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <div class="content">
            <div class="title">{{$t('index.难度值')}}</div>
            <div class="text">{{ difficulty }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <div class="content" style="margin-right: 0">
            <div class="title">{{$t('index.哈希率')}}</div>
            <div class="text">{{ hashrate }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{$t('index.区块高度')}}</span>
            </div>
          </template>
          <el-table
            :data="blockList"
            style="width: 100%"
          >
            <el-table-column :label="$t('index.区块高度')" width="100px">
              <template #default="scope">
                <router-link :to="{name: 'BlockDetial', params: { blockNumber: scope.row.number }}">{{ scope.row.number }}</router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('block.区块哈希值')">
              <template #default="scope">
                <span class="text-ellipsis">{{ scope.row.hash }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="120px"
              prop="transactionNum"
              :label="$t('block.交易次数')"
            ></el-table-column>
            <el-table-column :label="$t('block.区块生成者')">
              <template #default="scope">
                <router-link class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.miner }}">{{ scope.row.miner }}</router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('block.生成时间')">
              <template #default="scope">
                <span class="text-ellipsis">{{
                  dateFormat(
                    "yyyy-MM-dd hh:mm:ss",
                    new Date(scope.row.timestamp * 1000)
                  )
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{$t('index.交易记录')}}</span>
            </div>
          </template>
          <el-table
            :data="transactionList"
            style="width: 100%"
          >
          <el-table-column label="Hash">
              <template #default="scope">
                <router-link class="text-ellipsis" :to="{ name: 'TransactionDetial', params: { hash: scope.row.hash }}">{{ scope.row.hash }}</router-link>
              </template>
            </el-table-column>
            <el-table-column
              width="80px"
              :label="$t('index.区块高度')"
            >
             <template #default="scope">
                <router-link :to="{name: 'BlockDetial', params: { blockNumber: scope.row.blockNumber }}">{{ scope.row.blockNumber }}</router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('transaction.转出地址')">
               <template #default="scope">
                <router-link class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.fromAddress }}">{{ scope.row.fromAddress }}</router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('transaction.转入地址')">
              <template #default="scope">
                <router-link v-if="scope.row.toAddress" class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.toAddress }}">{{ scope.row.toAddress }}</router-link>
                <span class="text-ellipsis" v-else>{{$t('transaction.创建合约')}}</span>
              </template>
            </el-table-column>
            <el-table-column width="80px" :label="$t('transaction.数量')">
              <template #default="scope">
                <span class="text-ellipsis"
                  >{{
                    web3.utils.fromWei(scope.row.ethValue, "ether")
                  }}
                  ds</span
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('transaction.时间')">
              <template #default="scope">
                <span class="text-ellipsis">{{
                  dateFormat(
                    "yyyy-MM-dd hh:mm:ss",
                    new Date(scope.row.timestamp * 1000)
                  )
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n"
import {
  getGasPrice,
  getHashrate,
  getBlockNumber,
  getBlockInfo,
  getBlockList,
  getTransactionList
} from "@/api";
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      blockNumber: 0,
      hashrate: 0,
      gasPrice: 0,
      difficulty: 0,
      blockList: [],
      transactionList: [],
      toBlockDetail: (row, column, event) => {
        router.push({
          name: "BlockDetial",
          params: { blockNumber: row.number }
        });
      },
      toTransactionDetail: (row, column, event) => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      }
    });
    onMounted(() => {
      getGasPrice().then(res => {
        data.gasPrice = res.data;
      });
      getHashrate().then(res => {
        data.hashrate = res.data;
      });
      getBlockNumber()
        .then(res => {
          data.blockNumber = res.data;
          return getBlockInfo({ blockNumber: res.data });
        })
        .then(block => {
          data.difficulty = block.data.difficulty;
        });
      getBlockList().then(res => {
        data.blockList = res.data;
      });
      getTransactionList().then(res => {
        data.transactionList = res.data;
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.top {
  margin-bottom: 30px;
}
.grid-content {
  .content {
    background: #fff;
    margin: 0 20px;
    padding: 20px;
    border-radius: 10px;
    .title {
      font-size: 18px;
      color: #666;
      margin-bottom: 5px;
    }
    .text {
      font-size: 26px;
      font-weight: bold;
      color: #333;
    }
  }
}
.card-header {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}
</style>
