<template>
  <div class="header-box">
    <el-row>
      <el-col :span="4"
        ><div class="grid-content flex-middle">
          <el-image class="img-box" :src="require('@/assets/logo.png')"></el-image>
        </div>
      </el-col>
      <el-col :span="9"
        ><div class="grid-content">
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#409eff"
            text-color="#fff"
            active-text-color="#fff"
          >
            <el-menu-item index="/">{{$t('index.首页')}}</el-menu-item>
            <el-menu-item index="/block">{{$t('block.区块')}}</el-menu-item>
            <el-menu-item index="/transaction">{{$t('transaction.交易')}}</el-menu-item>
            <el-menu-item index="/token">{{$t('token.代币')}}</el-menu-item>
          </el-menu>
        </div></el-col>
        <el-col :span="3"
        ><div class="grid-content flex-middle language">
          <el-dropdown @command="changeLanuage">
  <span class="el-dropdown-link">
    {{local === "zh-cn" ? $t('index.中文') : $t('index.英文')}}<i class="el-icon-arrow-down el-icon--right"></i>
  </span>
  <template #dropdown>
    <el-dropdown-menu>
      <el-dropdown-item :class="{'active': local === 'zh-cn'}" command="zh-cn">{{$t('index.中文')}}</el-dropdown-item>
      <el-dropdown-item :class="{'active': local === 'en'}" command="en">{{$t('index.英文')}}</el-dropdown-item>
    </el-dropdown-menu>
  </template>
</el-dropdown>
        </div></el-col
      >
      <el-col :span="8"
        ><div class="grid-content flex-middle">
          <el-input v-model="search" :placeholder="$t('index.区块号/交易HASH/地址')"></el-input>
          <el-button style="margin: 0 20px 0 10px" @click="onSubmit"
            >{{$t('index.搜索')}}</el-button
          >
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import web3 from "web3"
import { getLang, setLang } from "@/utils/i18n"
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      search: "",
      local: getLang() || "zh-cn",
      changeLanuage: (val) => {
        setLang(val)
        data.local = val
      },
      onSubmit: () => {
        if (!data.search) {
          ElMessage.error(data.$t('index.请先输入内容'));
        }
        if (web3.utils.isAddress(data.search.toLowerCase().trim())) {
          console.log(111)
           router.push({
            name: "AddressDetial",
            params: { address: data.search.toLowerCase().trim() }
          });
        } else if (data.search.toLocaleLowerCase().includes('0x')) {
          router.push({
            name: "TransactionDetial",
            params: { hash: data.search }
          });
        } else if (Number(data.search)) {
          router.push({
            name: "BlockDetial",
            params: { blockNumber: data.search }
          });
        } else {
          ElMessage.error(data.$t('index.请先输入正确的搜索内容'));
        }
      }
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss">
.language{
  justify-content: flex-end;
  padding-right: 20px;
  .el-dropdown{
    color: #fff;
  }
}
.el-dropdown-menu__item.active{
  color: #409eff;
}
</style>

<style lang="scss" scoped>
.header-box {
  background: #409eff;
  .grid-content {
    height: 100%;
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }
    .el-menu.el-menu--horizontal {
      border: 0;
    }
    .img-box {
      width: 70%;
      height: 40px;
      padding-left: 20px;
    }
  }
}

</style>
