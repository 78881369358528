<template>
  <el-container>
    <el-header>
      <Header />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>
<script>
import Header from "@/components/Header";
export default {
  components: {
    Header
  },
 setup() {
 }
};
</script>
<style lang="scss">
@import "./assets/style/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  min-width: 1200px;
  .el-container{
    height: 100%;
  }
  .el-header {
    padding: 0;
  }
  a{
    color: #409eff;
  }
}
</style>
